import React from 'react';
import Layout from '../components/layout';
import PostsComponent from '../components/PostsComponent';

const monthInText_arr = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];

const Posts = ({ pageContext }) => {
    const pageTitle = `${pageContext.kernel_name} on ${monthInText_arr[pageContext.monthDate-1]} ${pageContext.dayDate}, ${pageContext.yearDate}`;
    return (
        <Layout menuList={[pageContext.lang.name, pageContext.yearDate, pageContext.monthDate, pageContext.dayDate]} pageTitle={pageTitle} pageLang={pageContext.lang}>
            <PostsComponent
            postsContent={pageContext.content}
            pageTitle={pageTitle}
            language={pageContext.lang.name}
            yearDate={pageContext.yearDate}
            monthDate={pageContext.monthDate}
            dayDate={pageContext.dayDate}/>
        </Layout>
    );
};

export default Posts;