import React from 'react';
import { Link } from "gatsby";

const box_title = {
    color: '#000',
    flex: 1
}

const lin = {
    userSelect: 'none',
    wordBreak: 'break-word',
    textDecoration: 'none',
    display: 'inline-flex',
    flexDirection: 'column'
}

const PostBlank = ({link, title, date}) => {
    return (
        <div style={{marginBottom: '10px', display: 'flex'}} className={'col-md-3 col-sm-6'}>
            <Link style={lin} to={link}>
                <h4 style={box_title}>{title}</h4>
                <div className={'col-md-12'} style={{color: '#5a5a5a', paddingLeft: '8px', fontSize: '14px', flex: 0}}>{date}</div>
            </Link>
        </div>
    );
};

export default PostBlank;