import React from 'react';
import PostBlank from "./PostBlank";

const PostsComponent = ({postsContent, pageTitle, language, yearDate, monthDate, dayDate}) => {
  return (
    <div className={'row'}>
        <div className={'col-sm-12'}>
            <h1 style={{marginTop: '30px'}}>{pageTitle}</h1>
        </div>
        {postsContent.map((elem) => {
            let postTitle;
            if (elem.uri[elem.uri.length-1] === '/') {
                postTitle = elem.uri.slice(0, elem.uri.length-1).split('/')
                postTitle = postTitle[postTitle.length-1]
            } else {
                postTitle = elem.uri.split('/')
                postTitle = postTitle[postTitle.length-1]
            }
            postTitle = postTitle.split('?')[0];
            let htmlFile = ''
            if (postTitle.indexOf('.html') === -1) {
                htmlFile = '.html'
            }
            return (
                <PostBlank
                    key={Date.now() + Math.random()}
                    _id = {elem._id}
                    link= {'/'+language+'/'+yearDate+'/'+monthDate+'/'+dayDate+'/'+postTitle+htmlFile}
                    title= {elem.text}
                    date= {elem.date.split('T')[1].split(':')[0]+':'+elem.date.split('T')[1].split(':')[1]}
                />
            )
        })}
    </div>
  )
}

export default PostsComponent;